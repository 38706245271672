<script>
import jQuery from "jquery";
import VueElementLoading from "vue-element-loading";

import BillingFilter from "@/components/BillingFilter.vue";
import BillingTable from "@/components/BillingTable.vue";
import api from "@/api";

export default {
  name: "BillingUnpaid",
  props: ["spaces"],
  components: {
    BillingFilter,
    BillingTable,
    VueElementLoading,
  },
  data: function () {
    return {
      loading: false,
      not_paid_list: [],
      filter: "",
      current_filters: "",
      page_no: 1,
      has_more: true,
    };
  },
  methods: {
    showPaymentField(itemId, amount) {
      jQuery(".toHide-" + itemId).hide();
      jQuery("#btnPayment-" + itemId).show();
      jQuery("#input-" + itemId).val(amount);
      jQuery("#input-" + itemId).show();
      jQuery("#btnComplete-" + itemId).show();
    },
    restore(itemId) {
      jQuery(".toHide-" + itemId).show();
      jQuery("#btnPayment-" + itemId).hide();
      jQuery("#btnComplete-" + itemId).hide();
      jQuery("#input-" + itemId).val("");
      jQuery("#input-" + itemId).hide();
    },
    markPaid(itemId) {
      var amount = jQuery("#input-" + itemId).val();
      var paymentMethod = jQuery("#btnPayment-" + itemId).val();
      var data = { invoice_id: itemId, amount: amount };
      if (paymentMethod) {
        data.paymentMethod = paymentMethod;
      }

      jQuery("#loader").show();

      const url = "/api/newPayment/";
      api.Util.axios
        .post(url, data)
        .then(() => {
          jQuery("#loader").hide();
          this.restore(itemId);
          this.getUnPaid();
        })
        .catch(() => {
          jQuery("#loader").hide();
          this.restore(itemId);
          alert("Error creating new payment.");
        });
    },
    getUnPaid(filters, page) {
      this.loading = true;
      this.not_paid_list.splice(0);

      var url = "/Invoices/";
      const params = {
        status: "unpaid",
      };
      if (!filters) {
        filters = this.current_filters;
      } else {
        this.current_filters = filters;
      }

      if (filters) {
        var j = JSON.stringify(filters);
        params.f = j;
      }

      var num = 20;
      if (page) {
        params.page_no = page;
      }
      params.num = num;

      api.Manager.axios.get(url, { params: params }).then((response) => {
        this.loading = false;
        this.not_paid_list.splice();
        var d = response.data;
        if (d.results) {
          d = d.results;
        }
        this.not_paid_list = d;
        this.has_more = !(d.length < num);
      });
    },
    onNext() {
      this.page_no += 1;
      this.getUnPaid(null, this.page_no);
    },
    onPrevious() {
      this.page_no -= 1;
      if (this.page_no < 1) {
        this.page_no = 1;
      }
      this.getUnPaid(null, this.page_no);
    },
  },
  watch: {
    filter() {
      this.getUnPaid();
    },
  },
  created() {
    this.getUnPaid();
  },
};
</script>

<template>
  <div id="BillingUnpaid" class="container-fluid">
    <vue-element-loading :active="loading" spinner="ring" color="#64B963" />
    <div class="col">
      <billing-filter @applyFilters="getUnPaid" />
    </div>

    <div class="container">
      <billing-table
        @reloadData="getUnPaid"
        :has_more="has_more"
        :payments="not_paid_list"
        :page="page_no"
        @onPrevious="onPrevious"
        @onNext="onNext"
        unpaid_table="1"
      />
    </div>
  </div>
</template>

<style lang="scss">
select.form-control {
  font-size: 13px;
}
#BillingUnpaid {
  padding-top: 10px;
}
.hidden {
  display: none;
}
</style>
